import { Icon, type IconName, type IconSizes } from '@/icons/icon'
import { type ReactNode } from 'react'

interface TagBaseProps {
  type: TagBaseTypes
  text?: string
  size?: string
  className?: string
}

export type TagBaseTypes =
  | 'discount'
  | 'topPick'
  | 'hotDeal'
  | 'whatsNew'
  | 'halfPrice'
  | 'priceDrop'
  | TagTypes

export enum TagTypes {
  discount = 'discount',
  topPick = 'topPick',
  hotDeal = 'hotDeal',
  whatsNew = 'whatsNew',
  halfPrice = 'halfPrice',
  priceDrop = 'priceDrop',
}

const TagContainer = ({
  children,
  type,
  size,
  color,
  className,
  ...props
}: {
  children: ReactNode
  type: TagBaseTypes
  size?: string
  color: 'dark' | 'light'
  ['data-testid']?: string
  className?: string
}) => {
  let bkgColor = 'bg-accent4'
  switch (type) {
    case TagTypes.discount:
    case TagTypes.topPick:
      bkgColor = 'bg-accent4'
      break

    case TagTypes.hotDeal:
      bkgColor = 'bg-accent5'
      break

    case TagTypes.whatsNew:
      bkgColor = 'bg-primary'
      break

    case TagTypes.priceDrop:
      bkgColor = 'bg-dark'
      break

    case TagTypes.halfPrice:
      bkgColor = 'bg-accent3'
      break
  }

  const height = size === 'xxs' ? 'h-5 !text-[9px]' : 'h-6'

  const textColor = color === 'light' ? 'text-white' : 'text-dark'

  const position = size === 'xxs' ? 'absolute left-2 top-2' : ''

  return (
    <div
      {...props}
      className={`${bkgColor} ${textColor} ${position} z-10 flex w-max items-center rounded px-2 py-0 text-xs font-bold ${height} ${className}`}
    >
      {children}
    </div>
  )
}

const getColor = (type: TagBaseTypes) => {
  switch (type) {
    case TagTypes.hotDeal:
    case TagTypes.whatsNew:
    case TagTypes.priceDrop:
      return 'light'
    default:
      return 'dark'
  }
}

const getIconName = (type: TagBaseTypes) => {
  switch (type) {
    case TagTypes.discount:
      return 'tags-bold'
    case TagTypes.halfPrice:
      return 'half-price-regular'
    case TagTypes.topPick:
      return 'rating-star-bold'
    case TagTypes.hotDeal:
      return 'trends-hot-flame-bold'
    case TagTypes.whatsNew:
      return 'megaphone-bold'
    case TagTypes.priceDrop:
      return 'price-drop-regular'
    default:
      return ''
  }
}

const getTagText = (type: TagBaseTypes) => {
  switch (type) {
    case TagTypes.discount:
      return 'Discount'
    case TagTypes.hotDeal:
      return 'Hot deal'
    case TagTypes.topPick:
      return 'Top pick'
    case TagTypes.whatsNew:
      return "What's new"
    case TagTypes.halfPrice:
      return 'Half-price'
    case TagTypes.priceDrop:
      return 'Price-drop'
    default:
      return ''
  }
}

export const Tag = ({ type, text, size = 'xs', className }: TagBaseProps) => {
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  const iconName = getIconName(type)

  const color = getColor(type)

  const tagText = text ?? getTagText(type)

  return (
    <TagContainer
      type={type}
      size={size}
      data-testid={'product-emphasis-tag'}
      color={color}
      className={className}
    >
      {iconName && (
        <Icon
          name={iconName as IconName}
          size={size as IconSizes}
          color={color}
          strokeWidth='0'
          className='mr-2'
        />
      )}
      {/* <TagLabel size={size} variant='labelXSmallBold' color={color}> */}
      {tagText}
      {/* </TagLabel> */}
    </TagContainer>
  )
}
