/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */

import {
  type ProductLabelProps,
  useProductLabel,
} from '@/utils/functions/use-product-label'

import {
  type DiscountPriceProps,
  getFormattedDiscountPrice,
  getFormattedOriginalPrice,
  getFormattedSavings,
} from '@/utils/functions/price-format'
import { uomRegex } from '@/utils/functions/uom-utils'
/* eslint-disable sonarjs/no-duplicate-string */
import React, { Suspense } from 'react'

import { type Breakpoint } from '@/components/utility/size/show-on-size'
/* eslint-disable sonarjs/no-identical-functions */
import { Tag } from '@/components/tag/tag'
import { ResolvedImage } from '@/components/utility/image'
import { WishListLoveItButton } from '@/components/wishlist/wishlist-button'
import {
  layoutLarge,
  layoutMedium,
  layoutSmall,
  layoutXLarge,
} from '@/lib/breakpoints'
import { type AdvertData } from '@/lib/data/products/get-advert-server'
import { type Maybe, type Product } from '@/lib/generated/graphql'
import { routeCalculatorRelative } from '@/lib/route-calculator'
import dynamic from 'next/dynamic'
import { type ImageProps } from 'next/image'
import Link from 'next/link'
import {
  type ElementVerticalProps,
  type SavingsLabelProps,
  type SizeOverride,
  Description,
  Footer,
  ImageColumn,
} from './product-card-components'

const ProductCardTimer = dynamic(
  async () => await import('./product-card-timer'),
  {
    ssr: false,
  },
)

export interface ProductCardProps {
  addToCartOnClick?: (variantId: string) => void
  breakPointForSizeTransition?: Breakpoint
  overrideWidth?: string | number
  vertical?: boolean
  fixedWidth?: boolean
  product: Product
  eventNameOverride?: string
  overrideAddToCartEvent?: string
  available: boolean
  advert: AdvertData
}

interface BrandLinkProps {
  brandLink: string
  brand?: Maybe<string>
}

const ProductLabel = (props: ProductLabelProps) => {
  const labelType = useProductLabel(props)
  return labelType ? <Tag type={labelType} size='xxs' /> : null
}

const getPrices = (product: Product): DiscountPriceProps => ({
  productPrice: product?.product_price,
  productDiscountPrice: product?.product_salePrice,
})

interface DiscountPriceLabelProps extends ElementVerticalProps, SizeOverride {
  from: boolean
  children?: React.ReactNode
  'data-testid': string
}

const getSmallFont = (breakpoint: Breakpoint) => {
  switch (breakpoint) {
    case 'sm':
      return 'sm:text-sm'
    case 'md':
      return 'md:text-sm'
    case 'xl':
      return 'xltext-sm'
    case 'xs':
      return ''
    default:
      return 'lg:text-sm'
  }
}

const getXLFont = (breakpoint: Breakpoint) => {
  switch (breakpoint) {
    case 'sm':
      return 'sm:text-2xl'
    case 'md':
      return 'md:text-2xl'
    case 'xl':
      return 'xltext-2xl'
    case 'xs':
      return ''
    default:
      return 'lg:text-2xl'
  }
}

const breakPoints = {
  xs: 0,
  sm: layoutSmall.down,
  md: layoutMedium.down,
  lg: layoutLarge.down,
  xl: layoutXLarge.down,
}

const DiscountPriceLabel = ({
  from,
  breakPointForSizeTransition,
  children,
  ...props
}: DiscountPriceLabelProps) => (
  <div
    className={`m-0 block ${
      props.vertical ? 'mt-0' : 'mt-2'
    } text-lg font-bold text-text-secondary ${getXLFont(
      breakPointForSizeTransition,
    )}`}
    {...props}
  >
    {from && (
      <span className={`text-xs ${getSmallFont(breakPointForSizeTransition)}`}>
        from{' '}
      </span>
    )}
    {children}
  </div>
)

const SavingsLabel = (
  savingsProps: SavingsLabelProps & { vertical: boolean },
) => {
  const { prices, breakPointForSizeTransition, vertical, ...props } =
    savingsProps ?? {}
  const savings = getFormattedSavings(prices)
  if (savings) {
    return (
      <div
        className={`m-0 line-clamp-1 min-h-[20px] overflow-hidden text-xs text-neutral-dark ${vertical ? 'block' : 'hidden'} ${getSmallFont(
          breakPointForSizeTransition,
        )}`}
        {...props}
      >
        Save {savings}
      </div>
    )
  }
  return null
}

const BrandLink = (props: BrandLinkProps) => {
  const { brandLink, brand } = props ?? {}
  if (brandLink == null || brandLink.length === 0) return null

  return (
    <Link
      href={brandLink}
      className='mb-3 text-xs text-neutral-dark underline'
      prefetch={false}
      rel='search'
    >
      {brand ?? ''}
    </Link>
  )
}

export const Card = (cardProps: {
  vertical?: boolean
  className?: string
  children?: React.ReactNode
  breakPointForSizeTransition?: Breakpoint
  fixedWidth?: boolean
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const {
    vertical,
    className,
    children,
    breakPointForSizeTransition,
    fixedWidth = false,
    ...props
  } = cardProps ?? {}

  let largeSize = vertical
    ? `lg:h-[450px] ${
        fixedWidth ? 'lg:w-[228px]' : 'lg:min-w-[228px]'
      } lg:pt-[210px]`
    : `lg:h-[192px] ${
        fixedWidth ? 'lg:w-[393px]' : 'lg:min-w-[393px]'
      } lg:pl-[150px]`

  switch (breakPointForSizeTransition) {
    case 'sm':
      largeSize = vertical
        ? `sm:h-[450px] ${
            fixedWidth ? 'sm:w-[228px]' : 'sm:min-w-[228px]'
          } sm:pt-[210px]`
        : `sm:h-[192px] ${
            fixedWidth ? 'sm:w-[393px]' : 'sm:min-w-[393px]'
          } sm:pl-[150px]`
      break
    case 'md':
      largeSize = vertical
        ? `md:h-[450px] ${
            fixedWidth ? 'md:w-[228px]' : 'md:min-w-[228px]'
          } md:pt-[210px]`
        : `md:h-[192px] ${
            fixedWidth ? 'md:w-[393px]' : 'md:min-w-[393px]'
          } md:pl-[150px]`
      break
    case 'xl':
      largeSize = vertical
        ? `xl:h-[450px] ${
            fixedWidth ? 'xl:w-[228px]' : 'xl:min-w-[228px]'
          } xl:pt-[210px]`
        : `xl:h-[168px] ${
            fixedWidth ? 'xl:w-[393px]' : 'xl:min-w-[393px]'
          } xl:pl-[150px]`
      break
  }

  const smallSize = vertical
    ? `h-[372px] ${
        fixedWidth ? 'w-[176px]' : 'min-w-[176px]'
      } m-0 flex-1 flex-col pt-[155px]`
    : `h-[168px] ${
        fixedWidth ? 'w-[304px]' : 'min-w-[304px]'
      } my-2 mr-0 flex-[0] flex-row pl-[140px] `
  return (
    <div
      {...props}
      className={`relative box-border flex flex-col content-between justify-start rounded-lg border  border-solid border-neutral bg-white p-4 ${smallSize} ${largeSize} ${className ?? ''}`}
    >
      {children}
    </div>
  )
}

const ProductImage = ({
  vertical,
  ...props
}: ImageProps & { vertical?: boolean }) => {
  if (vertical) {
    return (
      <ResolvedImage
        className=' size-[196px] rounded-[4px] border-none object-contain'
        fill
        {...props}
        sizes='(max-width: 1280px) 144px, 196px'
      />
    )
  }
  return (
    <ResolvedImage
      className='absolute inset-y-2 left-2 m-0 size-[130px] rounded-[4px] border-none object-contain'
      fill
      {...props}
      sizes='(max-width: 1280px) 110px, 130px'
    />
  )
}

const UnitPriceLabel = (unitPriceProps: {
  children?: React.ReactNode
  breakPointForSizeTransition?: Breakpoint
}) => {
  const { children, breakPointForSizeTransition, ...props } =
    unitPriceProps ?? {}
  if (children == null || (children as string).toString().length === 0)
    return null
  return (
    <span
      {...props}
      className={`m-0 line-clamp-1 overflow-hidden text-xs ${getSmallFont(
        breakPointForSizeTransition ?? 'lg',
      )}`}
    >
      {children}
    </span>
  )
}

const OriginalPriceLabel = (priceProps: {
  children?: React.ReactNode
  breakPointForSizeTransition?: Breakpoint
}) => {
  const { children, breakPointForSizeTransition, ...props } = priceProps ?? {}
  if (children == null || (children as string).toString().length === 0)
    return null
  return (
    <span
      {...props}
      className={`m-0 flex min-h-fit text-xs font-normal text-gray-500 ${getSmallFont(
        breakPointForSizeTransition ?? 'lg',
      )}`}
    >
      Don't Pay {children}&nbsp;{' '}
      <span
        className='cursor-pointer text-xs leading-5'
        title="The Don't Pay Price was nominated by the seller of the product as being the price at for which the same or nearly identical item is being offered for sale or has been offered for sale recently. The price may refer to the seller's own price elsewhere, or the price of another seller. The seller assumes sole responsibility for ensuring the accuracy of this price."
      >
        &#9432;
      </span>
    </span>
  )
}

export const ProductCard = (productProps: ProductCardProps) => {
  const {
    breakPointForSizeTransition = 'lg',
    fixedWidth,
    product,
    vertical = false,
    eventNameOverride,
    overrideAddToCartEvent,
    available,
    advert,
    ...props
  } = productProps ?? {}
  const prices = getPrices(product)
  const {
    product_savingsPercentage,
    product_startDate,
    product_price,
    product_salePrice,
    product_id,
    product_brand,
    product_imageUrl,
    product_retailer,
    product_name,
    product_endDate,
    product_hideDate,
    product_uomDescription,
    product_buyNow,
    product_multiPrice,
    product_categoryName,
    product_categoryId,
    product_advertLookUp,
    product_multiVariant,
    product_retailerId,
  } = product ?? {}

  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  const retailerId = (product_retailerId ||
    product_retailer?.retailer_id) as string

  const brandLink = routeCalculatorRelative.searchRoute(product_brand)

  const cardLink = routeCalculatorRelative.productRoute({
    retailerName: product_retailer?.retailer_displayName as string,
    productId: product_id as string,
    productName: product_name as string,
  })

  return (
    <Card
      data-testid='product-card'
      fixedWidth={fixedWidth}
      breakPointForSizeTransition={breakPointForSizeTransition}
      vertical={vertical}
    >
      <ProductLabel
        savingsPercentage={product_savingsPercentage}
        startDate={product_startDate}
        price={product_price}
        discountPrice={product_salePrice}
      />
      <ImageColumn
        breakPointForSizeTransition={breakPointForSizeTransition}
        data-testid='product-card-image'
        href={cardLink}
        productId={product_id}
        price={product_price}
        brand={product_brand}
        retailerId={retailerId}
        discountPrice={product_salePrice}
        description={product_name}
        vertical={vertical}
        eventNameOverride={eventNameOverride}
      >
        <ProductImage
          src={product_imageUrl ?? ''}
          alt={product_name as string}
          title={product_name as string}
          sizes={`(max-width: ${breakPoints[breakPointForSizeTransition]}px) 110px, 130px`}
        />
      </ImageColumn>

      {product != null && (
        <WishListLoveItButton
          className='absolute right-2 top-2 z-10 p-2'
          product={product}
        />
      )}
      <DiscountPriceLabel
        breakPointForSizeTransition={breakPointForSizeTransition}
        from={!!product_multiPrice}
        data-testid={`product-card-${vertical ? 'vertical' : 'horizontal'}-discount-price`}
      >
        {getFormattedDiscountPrice(prices)}
      </DiscountPriceLabel>
      <SavingsLabel
        prices={prices}
        breakPointForSizeTransition={breakPointForSizeTransition}
        vertical={vertical}
        data-testid={`product-card-${vertical ? 'vertical' : 'horizontal'}-savings`}
      />
      <OriginalPriceLabel
        breakPointForSizeTransition={breakPointForSizeTransition}
        data-testid={`product-card-${vertical ? 'vertical' : 'horizontal'}-original-price`}
      >
        {getFormattedOriginalPrice(prices)}
      </OriginalPriceLabel>
      <UnitPriceLabel
        breakPointForSizeTransition={breakPointForSizeTransition}
        data-testid={`product-card-${vertical ? 'vertical' : 'horizontal'}-unit-price`}
      >
        {product_uomDescription === product_name ||
        !product_uomDescription?.match(uomRegex)
          ? ''
          : product_uomDescription}
      </UnitPriceLabel>
      <Description
        data-testid='product-name'
        breakPointForSizeTransition={breakPointForSizeTransition}
        href={cardLink}
        productId={product_id}
        vertical={vertical}
        price={product_price}
        brand={product_brand}
        retailerId={retailerId}
        discountPrice={product_salePrice}
        description={product_name}
        eventNameOverride={eventNameOverride}
      >
        {product_name}
      </Description>
      <BrandLink brandLink={brandLink} brand={product_brand} />
      {vertical && (
        <Suspense fallback={<div className='h-4' />}>
          <ProductCardTimer
            product_endDate={product_endDate}
            product_startDate={product_startDate}
            product_hideDate={product_hideDate}
          />
        </Suspense>
      )}
      <Footer
        viewDetailsLink={cardLink}
        addToCartOnClick={props.addToCartOnClick}
        vertical={vertical}
        buyNow={product_buyNow as boolean}
        product_price={product_price}
        product_salePrice={product_salePrice}
        product_id={product_id}
        product_brand={product_brand}
        product_retailerId={retailerId}
        product_name={product_name}
        product_categoryName={product_categoryName}
        product_categoryId={product_categoryId}
        product_advertLookUp={product_advertLookUp}
        product_multiVariant={product_multiVariant}
        product_startDate={product_startDate}
        product_endDate={product_endDate}
        product_hideDate={product_hideDate}
        product_imageUrl={product_imageUrl}
        eventNameOverride={eventNameOverride}
        overrideAddToCartEvent={overrideAddToCartEvent}
        available={available}
        advert={advert}
      />
    </Card>
  )
}
