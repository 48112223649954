// eslint-disable-next-line no-useless-escape, prefer-regex-literals
export const uomRegex = new RegExp(`(per|each|\\$\\d+\\.\\d{2}\\/)`, 'gi')

export const determineUnitPrice = (
  uomDescription: string,
  productName: string,
): string | undefined => {
  if (!uomRegex.test(uomDescription)) {
    return uomDescription
  }

  if (uomDescription === productName) {
    return undefined
  }

  return uomDescription
}
