/* eslint-disable import/namespace */
import {
  type Product,
  type AdvertVariant,
  type User,
} from '@/lib/generated/graphql'
import { type Variant } from '@/lib/generated/platform-graphql'
import { type UserCart } from '@/components/cart/cart-types'
import { routeCalculator } from '@/lib/route-calculator'
import { getAbsoluteUrl } from './functions/url-utils'

import * as Sentry from '@sentry/nextjs'

export interface KlaviyoItem {
  ProductID: string
  SKU?: string
  ProductName: string
  Quantity: number
  ItemPrice?: number
  RowTotal?: number
  ProductURL: string
  ImageURL?: string
  ProductCategories?: string[]
}

export interface KlaviyoViewProductEvent {
  ProductName: string
  ProductID: string
  SKU?: string
  Categories: string[]
  ImageURL?: string
  URL: string
  Brand: string
  Price: number
  CompareAtPrice: number
}

export interface KlaviyoAddToCartEvent {
  $value?: number
  AddedItemProductName: string
  AddedItemProductID: string
  AddedItemSKU: string
  AddedItemCategories: string[]
  AddedItemImageURL: string
  AddedItemURL: string
  AddedItemPrice: number
  AddedItemQuantity: number
  ItemNames: string[]
  CheckoutURL: string
  Items: KlaviyoItem[]
}

export interface KlaviyoStartedCheckoutEvent {
  $event_id: string
  $value: number
  ItemNames: string[]
  CheckoutURL: string
  Categories: string[]
  Items: KlaviyoItem[]
}

export enum KlaviyoEventType {
  Checkout = 'Started Checkout',
  AddToCart = 'Added to Cart',
  ViewProduct = 'Viewed Product',
}

export const createViewedProductEventData = (
  product: Product,
  selectedVariant?: AdvertVariant | Variant,
) => {
  return {
    ProductName: product.product_name as string,
    ProductID: product.product_id as string,
    SKU: selectedVariant?.sku as string,
    Categories: [product.product_categoryName] as string[],
    ImageURL: `${getAbsoluteUrl(product.product_imageUrl ?? '')}`,
    URL: routeCalculator.productRoute({
      retailerName: product.product_retailer?.retailer_displayName,
      productId: product.product_id,
      productName: product.product_name,
    }) as string,
    Brand: product.product_brand as string,
    Price: product.product_salePrice as number,
    CompareAtPrice: product.product_price as number,
  }
}
export const createAddToCartEventData = ({
  product,
  selectedVariant,
  userCart,
}: {
  product: Product
  selectedVariant?: AdvertVariant | Variant
  userCart?: UserCart
}): KlaviyoAddToCartEvent => {
  const addedItemURL = routeCalculator.productRoute({
    retailerName: product.product_retailer?.retailer_displayName,
    productId: product.product_id,
    productName: product.product_name,
  })

  const addedItemCategories = [
    product.product_categorylevel3,
    product.product_categorylevel2,
    product.product_categorylevel1,
  ].filter(Boolean)

  const klaviyoItems = userCart?.items ? mapKlaviyoItems(userCart?.items) : []

  const existingIndex = selectedVariant?.sku
    ? klaviyoItems.findIndex(
        (klaviyoItem) =>
          klaviyoItem.ProductID === product.product_id &&
          klaviyoItem.SKU === selectedVariant?.sku,
      )
    : klaviyoItems.findIndex(
        (klaviyoItem) => klaviyoItem.ProductID === product.product_id,
      )
  if (existingIndex < 0) {
    klaviyoItems.push({
      ProductID: product.product_id as string,
      SKU: selectedVariant?.sku as string,
      ProductName: product?.product_name as string,
      Quantity: 1,
      ItemPrice: product.product_salePrice as number,
      RowTotal: product.product_price as number,
      ProductURL: addedItemURL,
      ImageURL: `${getAbsoluteUrl(product.product_imageUrl ?? '')}`,
      ProductCategories: addedItemCategories as string[],
    })
  } else {
    klaviyoItems[existingIndex].Quantity =
      klaviyoItems[existingIndex].Quantity + 1
  }

  return {
    $value: (userCart?.subtotalCents ?? 0) / 100,
    AddedItemProductName: product.product_name as string,
    AddedItemProductID: product.product_id as string,
    AddedItemSKU: selectedVariant?.sku as string,
    AddedItemCategories: addedItemCategories as string[],
    AddedItemImageURL: `${getAbsoluteUrl(product.product_imageUrl ?? '')}`,
    AddedItemURL: addedItemURL,
    AddedItemPrice: product.product_salePrice as number,
    AddedItemQuantity: 1,
    ItemNames: klaviyoItems.map((item) => item.ProductName),
    CheckoutURL: routeCalculator.checkout,
    Items: klaviyoItems,
  }
}

export const createStartedCheckOutEvent = (
  userCart: UserCart,
): KlaviyoStartedCheckoutEvent => {
  const eventId = userCart?.cartId ?? '' + Date.now()
  const klaviyoItems = mapKlaviyoItems(userCart?.items)
  const categories = [
    ...new Set(
      klaviyoItems.flatMap(
        (klaviyoItem) => klaviyoItem.ProductCategories as string[],
      ),
    ),
  ]
  return {
    $event_id: eventId,
    $value: (userCart?.subtotalCents ?? 0) / 100,
    ItemNames: klaviyoItems.map((item) => item.ProductName),
    CheckoutURL: routeCalculator.checkout,
    Categories: categories,
    Items: klaviyoItems,
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapKlaviyoItems = (items?: any): KlaviyoItem[] => {
  const klaviyoItems: KlaviyoItem[] = []
  if (items == null) {
    return []
  }
  for (const item of items) {
    klaviyoItems.push({
      ProductID: item.productId.toString(),
      SKU: item.variant?.sku as string,
      ProductName: item.product?.title as string,
      Quantity: item.quantity,
      ItemPrice: (item.variant?.salePriceInCents as number) / 100,
      RowTotal: item.product?.price as number,
      ProductURL: routeCalculator.productRoute({
        retailerName: item.retailer?.displayName,
        productId: item.productId,
        productName: item.product?.name,
      }),
      ImageURL: `${getAbsoluteUrl(item.product?.imageUrl ?? '')}`,
      ProductCategories: [
        item.product?.category?.level1,
        item.product?.category?.level2,
        item.product?.category?.level3,
      ].filter(Boolean) as string[],
    })
  }
  return klaviyoItems
}

export const sendKlaviyoEvent = <T>(
  event: T,
  eventType: KlaviyoEventType,
  user?: User,
): void => {
  if (typeof window !== 'undefined') {
    window.klaviyo?.identify(
      {
        email: user?.email,
        phone_number: user?.mobile,
        first_name: user?.firstName,
        last_name: user?.lastName,
      },
      () => {
        try {
          window.klaviyo?.push(['track', eventType, event])
        } catch (error) {
          Sentry.captureException(error)
        }
      },
    )
  }
}
