export const layoutSmall = {
  down: 616,
  up: 0,
}

export const layoutMedium = {
  down: 932,
  up: 617,
}

export const layoutLarge = {
  down: 1280,
  up: 933,
}

// The up down here appear to be reversed but this is a legacy problem that we might look at fixing when all tests are there!
export const layoutXLarge = {
  down: 1281,
  up: 1472,
}
