import dayjs from 'dayjs'
import { useMemo } from 'react'

export interface ProductLabelProps {
  startDate: string | null | undefined
  savingsPercentage: number | null | undefined
  price: number | null | undefined
  discountPrice: number | null | undefined
}

export const useProductLabel = ({
  startDate,
  savingsPercentage,
  price,
  discountPrice,
}: ProductLabelProps) =>
  useMemo(() => {
    if (startDate != null) {
      const dateNow = dayjs()

      const startDateFormated = dayjs(startDate, 'YYYY/MM/DD HH:mm:ss')

      const days = dateNow.diff(startDateFormated, 'd')

      if (days < 7 && days >= 0) {
        return 'whatsNew'
      }
    }

    if (savingsPercentage != null) {
      const number = Math.floor(savingsPercentage)
      if (number === 50) {
        return 'halfPrice'
      }

      if (number >= 51) {
        return 'hotDeal'
      }
    }

    const discount = discountPrice ?? 0
    if (discount > 0 && discount < (price ?? 0)) {
      return 'priceDrop'
    }
  }, [startDate, savingsPercentage, discountPrice, price])
